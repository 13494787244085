<template>
  <v-container id="user-tables" fluid tag="section">
    <base-material-card icon="mdi-clipboard-text" class="px-5 py-3">
      <v-card-title>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#1b5e20"
              class="white--text"
              elevation="2"
              v-bind="attrs"
              v-on="on"
              @click="clearForm"
              >Add Promotion</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="12">
                      <v-text-field
                        v-model="editedItem.name"
                        label="Promotion Name"
                        required
                        :rules="nameRules"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="12">
                      <v-select
                        v-model="editedItem.typPromotionId"
                        :items="typ_promotions"
                        attach
                        chips
                        @change="isCheck"
                        ref="typPromotionReff"
                        label="Select Type"
                        required
                        :rules="typPromotionIdRules"
                        item-text="type"
                        item-value="id"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="12">
                      <!-- <v-text-field v-model="productKeyword" append-icon="mdi-magnify"
                            label="Search Product promotion" single-line hide-details @input="doSearchProduct">
                          </v-text-field> -->
                      <v-select
                        v-model="editedItem.productIdX"
                        :items="products"
                        chips
                        multiple
                        item-text="title"
                        item-value="id"
                        ref="productNameReff"
                        label="Select Product promotion"
                        required
                        :rules="productIdXRules"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="12">
                      <v-text-field
                        v-model="productKeywordY"
                        append-icon="mdi-magnify"
                        label="Search Product result"
                        ref="productYKeyReff"
                        single-line
                        hide-details
                        @input="doSearchProductY"
                        disabled
                      >
                      </v-text-field>
                      <v-select
                        v-model="editedItem.productIdY"
                        :items="products_y"
                        attach
                        chips
                        ref="productYReff"
                        label="Select Product result"
                        :rules="productIdYRules"
                        item-text="title"
                        item-value="id"
                        disabled
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="12">
                      <v-menu
                        ref="start"
                        v-model="menuStart"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.start_date"
                            label="Start Date Promotion"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="start_dateRules"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.start_date"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="#1b5e20"
                            class="white--text"
                            @click="menuStart = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="#1b5e20"
                            class="white--text"
                            @click="$refs.start.save(editedItem.start_date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="12">
                      <v-menu
                        ref="end"
                        v-model="menuEnd"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.end_date"
                            label="End Date Promotion"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="end_dateRules"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker v-model="editedItem.end_date" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="#1b5e20"
                            class="white--text"
                            @click="menu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="#1b5e20"
                            class="white--text"
                            @click="$refs.end.save(editedItem.end_date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="12">
                      <v-text-field
                        v-model="editedItem.required"
                        label="Required qty"
                        type="number"
                        required
                        :rules="requiredRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="12">
                      <v-text-field
                        v-model="editedItem.result"
                        label="Result (qty/%)"
                        type="number"
                        required
                        :rules="resultRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="12">
                      <v-textarea
                        v-model="editedItem.description"
                        label="Description"
                        required
                        :rules="descriptionRules"
                        height="100"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#1b5e20" class="white--text" elevation="2" @click="close">
                Cancel
              </v-btn>
              <v-btn color="#1b5e20" class="white--text" elevation="2" @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <div class="warning text-center">
              <header class="text-h5">Confirmation</header>
            </div>
            <v-card-title class="text-h6"
              >Are you sure you want to delete this Promotion:{{ editedItem.name }}?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="#1b5e20"
                class="white--text"
                elevation="2"
                @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn
                color="#1b5e20"
                class="white--text"
                elevation="2"
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="keyword"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          @input="doSearch"
        >
        </v-text-field>
      </v-card-title>
      <v-data-table
        ref="vDataTable"
        :headers="headers"
        :items="promotions"
        hide-default-footer
        class="elevation-0"
      >
        <template #item="{ item, index, headers }">
          <tr>
            <td>
              {{ index + 1 + (page - 1) * 10 }}
            </td>
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ item.type }}
            </td>
            <td>
              {{ item.start_date }}
            </td>
            <td>
              {{ item.end_date }}
            </td>
            <td>
              <v-chip :color="getColor(item.status)" dark>
                {{ item.status == 1 ? "Active" : "Update" }}
              </v-chip>
            </td>
            <td>
              <v-icon small class="mr-2" @click="editItem(item)"> mdi-magnify </v-icon>
              <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="text-center">
        <v-pagination
          v-model="page"
          @input="go"
          :length="lengthPage"
          :total-visible="5"
          color="#1b5e20"
        ></v-pagination>
      </div>
    </base-material-card>
    <div class="text-center">
      <v-dialog v-model="progressBar" width="100" hide-overlay>
        <v-progress-circular
          :size="60"
          color="primary"
          indeterminate
          align-center
          justify-center
        ></v-progress-circular>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      promotions: [],
      keyword: "",
      page: 0,
      lengthPage: 0,
      headers: [
        { text: "No", value: "id" },
        { text: "Promotion", value: "name" },
        { text: "Type Promotion", value: "type" },
        { text: "Start Date", value: "start_date" },
        { text: "End Date", value: "end_date" },
        { text: "Status", value: "status" },
        { text: "Actions", sortable: false },
      ],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        id: "",
        name: "",
        start_date: "",
        end_date: "",
        required: "",
        result: "",
        productIdY: "",
        status: "",
        productIdX: "",
        typPromotionId: "",
        description: "",
      },
      defaultItem: {
        id: "",
        name: "",
        start_date: "",
        end_date: "",
        required: "",
        result: "",
        productIdY: "",
        status: "",
        productIdX: "",
        typPromotionId: "",
        description: "",
      },
      progressBar: false,
      isDisabled: true,
      products: [
        {
          value: "id",
        },
        {
          value: "title",
        },
      ],
      typ_promotions: [
        {
          value: "id",
        },
        {
          value: "type",
        },
      ],
      products_y: [
        {
          value: "id",
        },
        {
          value: "title",
        },
      ],
      nameRules: [
        (v) => !!v || "Promotion Name is required",
        (v) => (v && v.length <= 20) || "Max 20 characters",
      ],
      typPromotionIdRules: [(v) => !!v || "Type promotion is required"],
      productIdXRules: [(v) => !!v || "Product promotion is required"],
      start_dateRules: [(v) => !!v || "Promotion start date is required"],
      end_dateRules: [(v) => !!v || "Promotion end date is required"],
      requiredRules: [
        (v) => !!v || "Promotion nominal buy is required",
        (v) => /([0-9])/.test(v) || "Promotion nominal buy must be valid",
      ],
      resultRules: [
        (v) => !!v || "Promotion nominal result is required",
        (v) => /([0-9])/.test(v) || "Promotion nominal result must be valid",
      ],
      descriptionRules: [
        (v) => !!v || "Promotion description is required",
        (v) => (v && v.length <= 500) || "Max 500 characters",
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Promotion" : "Edit Promotion";
    },
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth: "auth/set",
    }),
    getColor(status) {
      if (status == 1) return "green";
      else return "orange";
    },
    go() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/promotion?page=" + this.page;
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          let { meta } = response.data;
          this.promotions = data;
          this.lengthPage = meta.last_page;
          this.page = meta.current_page;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    doSearch() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let keyword = this.keyword;
      if (keyword.length > 0) {
        this.axios
          .get("/promotion/search/" + keyword, config)
          .then((response) => {
            let { data } = response.data;
            this.promotions = data;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.go();
      }
    },
    deleteItem(item) {
      this.editedIndex = this.promotions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.progressBar = true;
      let formData = new FormData();
      formData.set("id", this.editedItem.id);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .post("/promotion/delete-permanent", formData, config)
        .then((response) => {
          let { data } = response;
          this.setAuth(data.data);
          this.setAlert({
            status: true,
            text: data.message,
            color: "success",
          });
          this.promotions.splice(this.editedIndex, 1);
          this.closeDelete();
          this.promotions.push(this.editedItem);
          this.go();
          this.progressBar = false;
        })
        .catch((error) => {
          let { data } = error;
          this.setAlert({
            status: true,
            text: data.message,
            color: "error",
          });
          this.promotions.push(this.editedItem);
          this.go();
          this.progressBar = false;
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    editItem(item) {
      this.editedIndex = this.promotions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      //select one menu
      this.doSearchTypPromotion();
      this.productXByPromotionId(this.editedItem.id);
      this.productYByPromotionId(this.editedItem.id);
      this.typPromotionByPromotionId(this.editedItem.id);
      this.dialog = true;
    },
    doSearchProduct() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let keyword = this.productKeyword;
      this.isDisabled = true;
      this.axios
        .get("/promotion/ajaxSearch/" + keyword, config)
        .then((response) => {
          let { data } = response.data;
          this.products = data;
        })
        .catch((error) => {
          console.log(error);
        });
      // if (keyword.length > 0) {
      //   this.axios
      //     .get("/promotion/productSearch/" + keyword, config)
      //     .then((response) => {
      //       let { data } = response.data;
      //       this.products = data;
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
      // }
      // this.$refs.productNameReff.update();
    },
    doSearchProductY() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let keyword = this.productKeywordY;
      if (keyword.length > 0) {
        this.axios
          .get("/promotion/productSearch/" + keyword, config)
          .then((response) => {
            let { data } = response.data;
            this.products_y = data;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.$refs.productYReff.update();
    },
    doSearchTypPromotion() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .get("/promotion/typPromotion", config)
        .then((response) => {
          let { data } = response.data;
          this.typ_promotions = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    save() {
      if (this.editedItem.typPromotionId == 3 && this.editedItem.productIdY == "") {
        this.setAlert({
          status: true,
          text: "Type promotion buy x get y, product result is required",
          color: "warning",
        });
        return;
      }

      if (this.$refs.form.validate()) {
        this.progressBar = true;
        let formData = new FormData();
        formData.set("name", this.editedItem.name);
        formData.set("id", this.editedItem.id);
        formData.set("productIdX", this.editedItem.productIdX);
        formData.set("typPromotionId", this.editedItem.typPromotionId);
        formData.set("start_date", this.editedItem.start_date);
        formData.set("end_date", this.editedItem.end_date);
        formData.set("required", this.editedItem.required);
        formData.set("result", this.editedItem.result);
        formData.set("description", this.editedItem.description);

        if (this.editedItem.productIdY != null) {
          formData.set("productIdY", this.editedItem.productIdY);
        }

        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        if (this.editedIndex > -1) {
          Object.assign(this.promotions[this.editedIndex], this.editedItem);
          this.axios
            .post("/promotion/update", formData, config)
            .then((response) => {
              let { data } = response;
              this.setAuth(data.data);
              this.setAlert({
                status: true,
                text: data.message,
                color: data.status,
              });
              this.promotions.push(this.editedItem);
              this.clear();
            })
            .catch((error) => {
              let { data } = error;
              this.setAlert({
                status: true,
                text: data.message,
                color: "error",
              });
              this.clear();
            });
        } else {
          this.axios
            .post("/promotion/store", formData, config)
            .then((response) => {
              let { data } = response;
              this.setAuth(data.data);
              this.setAlert({
                status: true,
                text: data.message,
                color: data.status,
              });
              this.promotions.push(this.editedItem);
              this.clear();
            })
            .catch((error) => {
              let { data } = error;
              this.setAlert({
                status: true,
                text: data.message,
                color: "error",
              });
              this.clear();
            });
        }
      }
    },
    isCheck() {
      if (this.editedItem.typPromotionId == 3) {
        this.isDisabled = false;
        this.$refs.productYReff.update();
      } else {
        (this.editedItem.productIdY = ""),
          (this.isDisabled = true),
          (this.productKeywordY = ""),
          this.$refs.productYReff.update(),
          this.$refs.productYKeyReff.update();
      }
    },
    clear() {
      this.go();
      this.close();
      this.progressBar = false;
      this.clearForm();
    },
    productXByPromotionId(keyword) {
      this.isDisabled = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .get("/promotion/productXByPromotionSearch/" + keyword, config)
        .then((response) => {
          let { data } = response.data;
          this.products = data;
          this.editedItem.productIdX = data[0].id;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    productYByPromotionId(keyword) {
      this.isDisabled = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .get("/promotion/productYByPromotionSearch/" + keyword, config)
        .then((response) => {
          let { data } = response.data;
          this.products_y = data;
          this.editedItem.productIdY = data[0].id;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    typPromotionByPromotionId(keyword) {
      this.isDisabled = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .get("/promotion/typPromotionByPromotionSearch/" + keyword, config)
        .then((response) => {
          let { data } = response.data;
          this.typ_promotions = data;
          this.editedItem.typPromotionId = data[0].id;
          this.isDisabled = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearForm() {
      this.doSearchTypPromotion();
      this.doSearchProduct();
      this.$refs.form.reset();
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.go();
    this.doSearchTypPromotion();
    this.doSearchProduct();
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.position-relative {
  position: relative;
}
</style>
